



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IExportPrecheckResponse } from '@/store/types/IExportPrecheckResponse';
@Component
export default class ExportOptionsPrecheckWarnings extends Vue {
  @Prop() precheckResult!: IExportPrecheckResponse | null;

  get prechecksProcessing() {
    return ExportUiStateModule.exportPrechecksProcessing;
  }
}
