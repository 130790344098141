
























import { Component, Vue } from 'vue-property-decorator';
import integrationsselectoritem from '@/components/integrationsselectoritem.vue';
import { IIntegration } from '@/store/types/IIntegrations';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import integrationsselector from '@/components/integrationsselector.vue';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
@Component({
  components: {
    integrationsselectoritem,
    integrationsselector,
  },
})
export default class ExportOptionsDestination extends Vue {
  get integrations() {
    return UserSettingsModule.integrations;
  }

  get selectedIntegrationID() {
    return ExportUiStateModule.selectedIntegrationID;
  }

  connectIntegration(integrationID: number) {
    UserSettingsModule.authenticateIntegration(integrationID);
  }
  selectIntegration(integration: IIntegration) {
    ExportUiStateModule.selectIntegration(integration.IntegrationID);
  }
}
