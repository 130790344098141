








































import { Component, Vue } from 'vue-property-decorator';
import exportoptionsadvancedformatting from '@/pages/export/components/ExportOptionsAdvancedFormatting.vue';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IntegrationType } from '@/store/types/Enums';
@Component({
  components: {
    exportoptionsadvancedformatting,
  },
})
export default class ExportOptionsAdvancedFormattingContainer extends Vue {
  advancedOptionsVisible = false;

  get IsSpreadsheetType() {
    return ExportUiStateModule.selectedIntegration!.IsSpreadsheetType;
  }
  get IsIntegrationWhichSupportsStoringFiles() {
    return ExportUiStateModule.selectedIntegration!
      .IsIntegrationWhichSupportsStoringFiles;
  }
  get ExportDestinationIsIntegration() {
    return (
      ExportUiStateModule.selectedIntegration!.IntegrationTypeID ===
      IntegrationType.Integration
    );
  }
}
