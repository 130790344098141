































import { Component, Vue } from 'vue-property-decorator';
import { IIntegration } from '@/store/types/IIntegrations';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import AuthModule from '@/store/modules/AuthModule';
import exportoptionsdestination from '@/pages/export/components/ExportOptionsDestination.vue';
import exportoptionsvideo from '@/pages/export/components/ExportOptionsVideo.vue';
import exportoptionsstart from '@/pages/export/components/ExportOptionsStart.vue';
import exportoptionssummary from '@/pages/export/components/ExportOptionsSummary.vue';
import exportoptionsadvancedformattingcontainer from '@/pages/export/components/ExportOptionsAdvancedFormattingContainer.vue';
import integrationconnectedmodal from '@/components/modals/IntegrationConnectedModal.vue';

@Component({
  components: {
    exportoptionsdestination,
    exportoptionsvideo,
    exportoptionsstart,
    exportoptionssummary,
    exportoptionsadvancedformattingcontainer,
    integrationconnectedmodal,
  },
})
export default class ExportOptionsPage extends Vue {
  get profile() {
    return AuthModule.profile;
  }
  get integrationHasBeenSelected() {
    return ExportUiStateModule.integrationHasBeenSelected;
  }
  // Only used to be watched
  get selectedIntegration(): IIntegration | undefined {
    return ExportUiStateModule.selectedIntegration;
  }

  get exportPrecheckResult() {
    return ExportUiStateModule.exportPrecheckResult;
  }

  get prechecksProcessing() {
    return ExportUiStateModule.exportPrechecksProcessing;
  }

  get exportCanProceed() {
    return ExportUiStateModule.exportPrecheckResult!.ExportCanProceed;
  }

  get integrationConnectedModalVisible() {
    return UserSettingsModule.integrationJustConnected;
  }
  set integrationConnectedModalVisible(value: boolean) {
    UserSettingsModule.integrationConnected(0);
  }

  get justConnectedToIntegration() {
    return UserSettingsModule.justConnectedToIntegration;
  }
}
