




















































































































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import exportoptionscontentpreview from '@/pages/export/components/ExportOptionsContentPreview.vue';
import exportoptionsmodal from '@/components/modals/ExportOptionsModal.vue';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import exportoptionsdocumentgranularityicons from '@/pages/export/components/ExportOptionsDocumentGranularityIcons.vue';
import exportoptionsprecheckwarnings from '@/pages/export/components/ExportOptionsPrecheckWarnings.vue';
import exportoptionsprecheckrecordcounts from '@/pages/export/components/ExportOptionsPrecheckRecordCounts.vue';

@Component({
  components: {
    exportoptionscontentpreview,
    exportoptionsmodal,
    exportoptionsdocumentgranularityicons,
    exportoptionsprecheckwarnings,
    exportoptionsprecheckrecordcounts,
  },
})
export default class ExportOptionsSummary extends Vue {
  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }
  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }
  get finalDocumentType() {
    return ExportUiStateModule.selectedExportFormatIntegration?.IntegrationName.toUpperCase();
  }
  get ExportDocumentMode() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.ExportDocumentMode;
  }

  get prechecksProcessing() {
    return ExportUiStateModule.exportPrechecksProcessing;
  }

  get precheckResult() {
    return ExportUiStateModule.exportPrecheckResult;
  }
}
