










































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IntegrationType } from '@/store/types/Enums';

@Component
export default class ExportOptionsStart extends Vue {
  acknowledgedoverwritewarning = false;
  startExport() {
    ExportUiStateModule.createExportJob(
      window.location.pathname.replace('options', ''),
    );
  }

  get createExportJobProcessing() {
    return ExportUiStateModule.createExportJobProcessing;
  }

  get resetExportedRecords() {
    return ExportUiStateModule.resetExportedRecords;
  }

  get integrationHasBeenSelected() {
    return ExportUiStateModule.integrationHasBeenSelected;
  }

  get startButtonDisabled() {
    return (
      (this.showOverwriteWarning &&
        !this.acknowledgedoverwritewarning) ||
      ExportUiStateModule.createExportJobProcessing
    );
  }

  get showAcceptOverwriteWarning() {
    return (
      this.showOverwriteWarning &&
      !this.acknowledgedoverwritewarning &&
      !ExportUiStateModule.createExportJobProcessing
    );
  }

  get showOverwriteWarning() {
    return (
      ExportUiStateModule.selectedIntegration?.IntegrationTypeID ===
        IntegrationType.Integration &&
      ExportUiStateModule.selectedIntegration?.IntegrationName !==
        'AmazonKindle'
    );
  }
}
