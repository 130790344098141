














































import { Component, Prop, Vue } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IExportPrecheckResponse } from '@/store/types/IExportPrecheckResponse';
@Component
export default class ExportOptionsPrecheckRecordCounts extends Vue {
  @Prop() precheckResult!: IExportPrecheckResponse | null;
  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }
  get selectedIntegration() {
    return ExportUiStateModule.selectedIntegration;
  }
  get finalDocumentType() {
    return ExportUiStateModule.selectedExportFormatIntegration?.IntegrationName.toUpperCase();
  }
  get ExportDocumentMode() {
    return ExportUiStateModule.selectedIntegration!
      .IntegrationExportSettings.ExportDocumentMode;
  }

  get prechecksProcessing() {
    return ExportUiStateModule.exportPrechecksProcessing;
  }
}
